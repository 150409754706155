import { AnimatePresence, motion } from 'framer-motion';
import {
    forwardRef, useImperativeHandle, useRef, useState,
} from 'react';

interface Props {
    children: JSX.Element[];
    canSwipeBetweenPages?: boolean;
    onChangePage?: (nextPage: number, direction: 1 | -1) => void;
}

export interface WithPagesForwardedActions {
    paginateNext: () => void;
    paginatePrevious: () => void;
}

const pagesVariants = {
    enter: (direction: number) => ({
        x:       direction > 0 ? 100 : -100,
        opacity: 0,
    }),
    center: {
        y:       0,
        zIndex:  1,
        x:       0,
        opacity: 1,
    },
    exit: (direction: number) => ({
        zIndex:  0,
        x:       direction < 0 ? 500 : -500,
        opacity: 0,
    }),
};

const swipeConfidenceThreshold = 10000;

const swipePower = (offset: number, velocity: number) => Math.abs(offset) * velocity;

const DraggablePopupPages = forwardRef(
    function Pages({ children, canSwipeBetweenPages, onChangePage }: Props, ref: React.Ref<WithPagesForwardedActions>) {
        const [[page, direction], setPage] = useState([0, 0]);

        const isFirstRender = useRef<boolean>(null);

        if (isFirstRender.current === null) {
            isFirstRender.current = true;
        } else {
            isFirstRender.current = false;
        }

        const paginate = (newDirection: 0 | 1 | -1) => {
            const nd = (newDirection ?? 1) as 1 | -1;

            setPage([page + newDirection, nd]);
            onChangePage?.(page + newDirection, nd);
        };

        useImperativeHandle(ref, () => ({
            paginateNext:     () => paginate(1),
            paginatePrevious: () => paginate(-1),
        }), [page, direction]);

        return (
            <AnimatePresence exitBeforeEnter>
                <motion.div

                    // using the key to rerun animate when page changes
                    key={children[page].key}

                    custom={direction}
                    variants={pagesVariants}
                    initial="enter"
                    animate="center"
                    exit="exit"

                    transition={{
                        x:       { type: 'spring', stiffness: 500, damping: 45 },
                        opacity: { duration: .2 },
                    }}

                    drag={canSwipeBetweenPages ? 'x' : false}
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDragEnd={(e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);

                        if (swipe < -swipeConfidenceThreshold) {
                            paginate(page + 1 > children.length - 1 ? 0 : 1);
                        } else if (swipe > swipeConfidenceThreshold) {
                            paginate(page - 1 < 0 ? 0 : -1);
                        }
                    }}
                >
                    {children[page]}
                </motion.div>
            </AnimatePresence>
        );
    },
);

export default DraggablePopupPages;
