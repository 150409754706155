/* eslint-disable max-len */
import { signalNotification } from 'oneSignal';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import taooPointIcon from 'assets/Images/taoo-point-icon.png';
import AnimatedItemsContainer from 'components/animation-wrapper/animated-items-container/ItemsAnimatedContainer';
import PageTransitionWrapper from 'components/animation-wrapper/page-transition-wrapper';
import TAOOHelmet from 'components/helmet/Helmet';
import Img from 'components/img/Img';
import LevelUpPopup from 'components/level/level-up-popup/LevelUpPopup';
import Loader from 'components/loaders/loader';
import AppNavigation from 'components/navigations/app-navigation';
import NoResult from 'components/no-result';
import usePostShop from 'hooks/shop/usePostShop';
import useGetCurrentUser from 'hooks/user/useGetCurrentUser';
import useGetPointsWheelStatus from 'pages/fortune-wheel/useGetPointsWheelStatus';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import replacePathParams from 'services/utils/replacePathParams';
import ClickoutOfferPopup from 'app/clickout-offer/ClickoutOfferPopup';
import useClickoutOffer from 'app/clickout-offer/useClickoutOffer';

import HomeFiltersWrapper from './filters-wrapper';
import styles from './index.module.scss';
import ShopItem from './shop-item';

function Home({
    hasPin = false, lang = 'fr', onboarded = false, steps,
}) {
    const navigate = useNavigate();
    const intl = useIntl();
    const currentUser = useGetCurrentUser();
    const [search, setSearch] = useState('');
    const [tags, setTags] = useState([]);
    const [shouldShowClickOut, setShouldShowClickout] = useState(false);
    const { wheelStatus, isWheelStatusLoading } = useGetPointsWheelStatus();
    const { isLoading: postShopDataIsLoading, shopItems, ref } = usePostShop(search, tags);

    const openClickout = () => setShouldShowClickout(true);
    const closeClickout = () => setShouldShowClickout(false);

    const { buttonText, isLoading: clickoutIsLoading, offer, onSubmit } = useClickoutOffer(
        function onCheck() {
            openClickout();
        },
        function onClaim() {
            closeClickout();
        }
    );

    useEffect(() => {
        signalNotification.init().then((result) => {
            // console.log('signal result:', result);

            signalNotification.subscribeWithEmail(currentUser.login);

            // signalNotification.login(String(currentUser.id), currentUser.token);
        });
    }, []);

    useEffect(() => {
        if (!onboarded && hasPin && !steps.later) {
            navigate(All_PATHS_NAMESPACES[lang].onboarding.path);
        }
    }, [onboarded, hasPin, steps, steps?.later]);

    const handleTagClick = (clickState) => {
        const foundIndex = tags.findIndex((el) => clickState.id === el);

        if (clickState.clicked === true) {
            if (foundIndex === -1) {
                setTags((tags) => (tags.length ? [...tags, clickState.id] : [clickState.id]));
            }
        } else {
            setTags((tags) => tags.filter((el) => el !== clickState.id));
        }
    };

    const handleShopItemClick = (id) => {
        navigate(
            replacePathParams(All_PATHS_NAMESPACES[lang]['voucher-details'].path, {
                id,
            }),
        );
    };

    const [showLevelupPopup, setShowLevelupPopup] = useState(false);

    // const hasLeveledUp = true;
    const currentLevel = 4;

    const isNotOnBoarded = !onboarded && hasPin && !steps?.later;

    return showLevelupPopup ? (
        <LevelUpPopup
            header={intl.formatMessage({ id: 'level.levelup.header' }, { level: currentLevel })}
            description={intl.formatMessage(
                { id: `level.levelup.level-${ currentLevel }.description` },
                { img: <Img src={taooPointIcon} alt="taoo point icon" /> },
            )}
            onClose={() => setShowLevelupPopup(false)}
        />
    ) : (
        !isNotOnBoarded && (
            <>
                <HomeFiltersWrapper 
                    wheelStatus={wheelStatus}
                    isWheelStatusLoading={isWheelStatusLoading}
                    handleChange={setSearch} 
                    handleTagClick={handleTagClick} 
                />

                <TAOOHelmet title={intl.formatMessage({ id: 'seo.page-title.shop' })} />

                <PageTransitionWrapper key="home" className="flex-grow-1">
                    <AnimatedItemsContainer className={styles['items-container']} shouldAnimate={false}>
                        {shopItems &&
                            shopItems?.length > 0 &&
                            shopItems.map((el, idx) => {
                                const isFirstOffer = idx === 0;

                                return (
                                    <ShopItem
                                        isFirstOffer={isFirstOffer}
                                        key={`${ el.id }-${ idx }`}
                                        id={isFirstOffer ? 'homepage-first-offer' : ''}
                                        item={el}
                                        onClick={() => handleShopItemClick(el.id)}
                                    />
                                );
                            })}

                        {(!shopItems || !shopItems?.length) && !postShopDataIsLoading && (
                            <NoResult message={<FormattedMessage id="filter.produced-no-results" />} />
                        )}

                        {postShopDataIsLoading && <Loader wrapperStyle={{ marginTop: '1.5rem' }} />}
                    </AnimatedItemsContainer>

                    <div className={styles.loader} ref={ref} />
                </PageTransitionWrapper>

                <AppNavigation wheelStatus={wheelStatus} isWheelStatusLoading={isWheelStatusLoading} />

                <ClickoutOfferPopup
                    title={intl.formatMessage({ id: 'clickout-offer.title' })}
                    paragraph={intl.formatMessage({ id: 'clickout-offer.paragraph' })}
                    shouldShow={shouldShowClickOut}
                    onClick={onSubmit}
                    isLoading={clickoutIsLoading}
                    buttonText={buttonText}
                    offer={offer}
                />
            </>
        )
    );
}

const mapStateToProps = (state) => ({
    hasPin:         state.user.hasPin,
    lang:           state.locale,
    onboarded:      state.user.onboarded,
    onboardingStep: state.user.onboardingStep,
    steps:          state.steps,
});

export default connect(mapStateToProps)(Home);
