import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import useToast from 'components/widgets/toast/useToast';
import useLocale from 'hooks/locale/useLocale';
import useGetCurrentUser from 'hooks/user/useGetCurrentUser';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import { deleteStorageItem, getStorageItem } from 'services/storage';
import { getCurrentUser } from 'services/utils/currentUser';
import TaooServerClient from 'http/axios';
import { endpoints } from 'constants/api';

import { CLICKOUT_OFFER_KEY } from './ClickoutOffer';

export default function useClickoutOffer(
    onCheck?: () => void,
    onClaim?: (status: 'success' | 'error') => void,
) {
    const language = useLocale();
    const navigate = useNavigate();
    const user = useGetCurrentUser();
    const intl = useIntl();
    const { triggerToast } = useToast();
    const [isLoading, setLoader] = useState(false);
    const [buttonText, setButtonText] = useState('ok');
    const [offer, setOffer] = useState(null);

    const isLoggedIn = () => !isEmpty(user) || !isEmpty(getCurrentUser());

    useEffect(() => {
        const code = getStorageItem(CLICKOUT_OFFER_KEY);

        if (!code) {
            return;
        }

        if (!isLoggedIn()) {
            setButtonText(intl.formatMessage({ id: 'clickout-offer.buttonLogin' }));
        } else {
            setButtonText(intl.formatMessage({ id: 'clickout-offer.buttonClaim' }));
        }

        setLoader(true);
        getHasClickoutOffer(code)
                .then((result) => {
                    if (result.success === false || !result) {
                        triggerToast(intl.formatMessage({ id: 'clickout-offer.code-error' }), 'error');

                        return;
                    }

                    setOffer(result);
                    onCheck?.();
                })
                .finally(() => setLoader(false));
    }, []);

    const onSubmit = () => {
        if (!isLoggedIn()) {
            navigate(All_PATHS_NAMESPACES[language].login.path);

            return;
        }

        const code = getStorageItem(CLICKOUT_OFFER_KEY);

        setLoader(true);
        submitClaim(code)
                .then((result) => {
                    if (!result || !result.cardId) {
                        triggerToast(intl.formatMessage({ id: 'clickout-offer.claim-error' }), 'error');
                        onClaim?.('error');

                        return;
                    }

                    triggerToast(intl.formatMessage({ id: 'clickout-offer.claim-success' }), 'success');

                    // delete after claiming
                    deleteStorageItem(CLICKOUT_OFFER_KEY);
                    onClaim?.('success');
                })
                .finally(() => setLoader(false));
    };

    return {
        onSubmit, offer, buttonText, isLoading,
    };
}

const submitClaim = async (code: string) => {
    let result = null;

    try {
        const response = await TaooServerClient.post(endpoints.checkout.claim, { code });

        if (!response || !response?.data || response.status === 400) {
            return;
        }

        result = response.data;
    } catch (e: unknown) {
        console.error(e);
    }

    return result;
};

const getHasClickoutOffer = async (code: string) => {
    try {
        const response = await TaooServerClient.get(endpoints.checkout.checkCode(code));

        if (!response || !response?.data || response?.status === 400 || response?.status === 404) {
            return { success: false, data: null, error: null };
        }

        return { success: true, data: response?.data, error: null };
    } catch (e: unknown) {
        console.error(e);

        return { success: false, data: null, error: e };
    }
};
